import React from 'react';

import { View } from 'react-native';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Datatable } from '@/components/datatable';
import { individualListHeaders } from './Individual.consts';
import { useIndividualList } from './Individual.functions.';
import IndividualTabs from '@/components/individualtabs/IndividualTabs';

export const IndividualListPage = () => {
  const {
    adminData,
    loading,
    individualDatatable,
    goToTransfer,
  } = useIndividualList();
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Container>
        <EntitiesPageTitle
          title={locale.t('individual')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
        <IndividualTabs individualList individualTransfer={false} />
          <View style={[PaperColumns.tableContainer]}>
            <Datatable
              headers={individualListHeaders}
              loading={loading}
              data={individualDatatable}
              onEditPress={goToTransfer}
              searchFields={['name']}
            />
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default IndividualListPage;
