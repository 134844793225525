import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import locale from '@/internationalization';
import { colors } from '@/consts';
import { Routes } from '@/navigation/routes';
import { individualTabsStyles } from './IndividualTabs.styles';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { IndividualTabsTypes } from './FinanceTabs.types';

export const IndividualTabs = ({
  individualList,
}: IndividualTabsTypes) => {
  const  navigation = useNavigate();
  return (
    <View style={individualTabsStyles.container}>
      <TouchableOpacity
        style={[
          individualTabsStyles.btnContainer,
          {
            borderColor: individualList
              ? colors.tabs.selectedBorder
              : colors.tabs.border,
          },
        ]}
        onPress={() =>
          navigation.navigate(Routes.IndividualList)
        }>
        <Text
          style={[
            individualTabsStyles.btnText,
            { color: individualList ? colors.tabs.selectedText : colors.tabs.text },
          ]}>
          {locale.t('individualTabs.profiles')}
        </Text>
      </TouchableOpacity>  
    </View>
  );
};

export default IndividualTabs;
